import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Constants } from "src/app/config/constants";
import { BaseURLParams } from "src/app/shared/interfaces/base-params";
import { ApiHttpService } from "../api-http.service";
import { ApiPaginationConfig, ApiVcpBase, EntityName, PageData, ServerResponse } from "./api.vcp.base.service";
import { Rule } from "./api.vcp.designer.service";
import { Playlist } from "./api.vcp.playlist.service";

@Injectable({
  providedIn: 'root'
})
export class ApiVcpAssetService extends ApiVcpBase {

  constructor(
    protected _apiHttpService: ApiHttpService,
    private _domSanitizer: DomSanitizer,
  ) {
    super();
  }

  /**
   * @description Adds a media asset
   * @param {FormData} image
   * @return {*}  {Observable<ServerResponse>}
   * @memberof ApiVcpAssetService
   */
  create(image: FormData): Observable<ServerResponse> {
    //POST ​​/api​/Asset​/Create/{customerId}/{tenantId}
    let params: BaseURLParams = {
      url: this._createUrl(`Asset/Create`),
      body: image
    }
    return this._apiHttpService.post(params);
  }

  /**
  * @description Adds a media asset
  * @param {FormData} image
  * @return {*}  {Observable<ServerResponse>}
  * @memberof ApiVcpAssetService
  */
  createMedia(image: FormData, tagName: string = null): Observable<any> {
    //POST ​​/api​/Asset​/CreateMedia/{customerId}/{tenantId}
    let params: BaseURLParams = {
      url: this._createUrl(`Asset/CreateMedia/${tagName}`),
      body: image
    }
    return this._apiHttpService.postMedia(params);
  }

  /**
   * @description Creates an url asset
   * @param {{ Name: string, Type: assetTypes, Url: string, Tags?: string }} data
   * @return {*}  {Observable<ServerResponse>}
   * @memberof ApiVcpAssetService
   */
  createUrl(data: { Name: string, Type: assetTypes, Url: string, Tags?: string }): Observable<ServerResponse> {
    //POST ​​/api​/Asset​/CreateUrl/{customerId}/{tenantId}
    let params: BaseURLParams = {
      url: this._createUrl(`Asset/CreateUrl`),
      body: data,
    }
    return this._apiHttpService.post(params);
  }

  /**
   * @description Creates an url asset
   * @param {{ Name: string, Type: assetTypes, Tags?: string }} data
   * @return {*}  {Observable<ServerResponse>}
   * @memberof ApiVcpAssetService
   */
  createGeneric(data: { Name: string, Type: assetTypes, Tags?: string }): Observable<ServerResponse> {
    //POST ​​/api​/Asset​/CreateGeneric/{customerId}/{tenantId}
    let params: BaseURLParams = {
      url: this._createUrl(`Asset/CreateGeneric`),
      body: data,
    }
    return this._apiHttpService.post(params);
  }

  /**
   * @description Gets a list of all the assets for the customer
   * @param {boolean} [inUse=false]
   * @param {boolean} [hasRelations=false]
   * @param {boolean} [includeRelations=false]
   * @param {ApiPaginationConfig} [paginationConfig]
   * @return {*}  {Observable<PageData>}
   * @memberof ApiVcpAssetService
   */
  getAll(inUse: boolean = false, hasRelations: boolean = false, includeRelations: boolean = false, excludeInteractiveAssets: boolean = false, paginationConfig?: ApiPaginationConfig): Observable<PageData<Array<MediaAsset>>> {
    //GET ​/api​/Asset​/GetAll​/{customerId}/{tenantId}
    paginationConfig = paginationConfig == undefined ? new ApiPaginationConfig() : paginationConfig;
    let params: BaseURLParams = {
      url: this._createUrl(`Asset/GetAll/${inUse}/${hasRelations}/${includeRelations}/${excludeInteractiveAssets}`),
      urlArguments: paginationConfig,
    }
    return this._apiHttpService.get(params)
      .pipe(map(response => {
        response.Data.map(asset => {
          // asset.ThumbnailURI = this.getAssetURI(asset);
          // asset.PreviewURI = this.getAssetURI(asset.PreviewURI);
          asset.AssetURI = this.getAssetURI(asset.AssetURI);
        });

        return response;
      }));
  }

  /**
   * @description Gets an asset by id for the customer
   * @param {string} assetId
   * @param {boolean} [hasRelations=false]
   * @param {boolean} [includeRelations=false]
   * @return {*}  {Observable<Asset>}
   * @memberof ApiVcpAssetService
   */
  getById(assetId: string, hasRelations: boolean = false, includeRelations: boolean = false): Observable<Asset> {
    //GET ​​/api​/Asset​/Get​/{customerId}​/{tenantId}/{id}
    let params: BaseURLParams = {
      url: this._createUrl(`Asset/Get/${assetId}/${hasRelations}/${includeRelations}`)
    }
    return this._apiHttpService.get(params);
  }

  /**
   * @description Edit a media asset
   * @param {string} assetId
   * @param {string} name
   * @param {string} tags
   * @return {*}  {Observable<ServerResponse>}
   * @memberof ApiVcpAssetService
   */
  updateMedia(assetId: string, name?: string, tags?: string): Observable<ServerResponse> {
    //PUT ​/api/Asset/UpdateMedia/{customerId}/{tenantId}/{id}
    let params: BaseURLParams = {
      url: this._createUrl(`Asset/UpdateMedia/${assetId}`),
      body: {
        Name: name,
        Tags: tags,
      }
    }
    return this._apiHttpService.put(params);
  }

  /**
   * @description Edit a media asset
   * @param {string} assetId
   * @param {string} name
   * @param {FormData} [image]
   * @return {*}  {Observable<ServerResponse>}
   * @memberof ApiVcpAssetService
   */
  changeMediaAsset(assetId: string, name: string, image: FormData): Observable<ServerResponse> {
    //PUT ​/api/Asset/ChangeMediaAsset/{customerId}/{tenantId}/{id}/{name}
    let params: BaseURLParams = {
      url: this._createUrl(`Asset/ChangeMediaAsset/${assetId}/${name}`),
      body: image
    }
    return this._apiHttpService.put(params);
  }

  /**
   * @description Edit a url asset
   * @param {string} assetId
   * @param {{ Name: string, Url: string, Tags: string }} data
   * @return {*}  {Observable<ServerResponse>}
   * @memberof ApiVcpAssetService
   */
  updateUrl(assetId: string, data: { Name: string, Url: string, Tags: string }): Observable<ServerResponse> {
    //PUT ​/api/Asset/UpdateUrl/{customerId}/{tenantId}/{id}
    let params: BaseURLParams = {
      url: this._createUrl(`Asset/UpdateUrl/${assetId}`),
      body: data,
    }
    return this._apiHttpService.put(params);
  }

  /**
   * @description Deletes an asset
   * @param {string} assetId
   * @return {*}  {Observable<Boolean>}
   * @memberof ApiVcpAssetService
   */
  deleteById(assetId: string): Observable<Boolean> {
    //DELETE ​/api​/Asset​/Delete​/{customerId}​/{tenantId}/{id}
    let params: BaseURLParams = {
      url: this._createUrl(`Asset/Delete/${assetId}`)
    }
    return this._apiHttpService.delete(params)
      .pipe(
        map((response: any) => {
          return (<ServerResponse>response).Success;
        }),
      );
  }

  /**
   * @description Gets a list of all tags for the customer
   * @param {ApiPaginationConfig} [paginationConfig]
   * @return {*}  {Observable<PageData>}
   * @memberof ApiVcpAssetService
   */
  getAllTags(paginationConfig?: ApiPaginationConfig): Observable<PageData<Array<Tags>>> {
    //GET ​/api​/Asset​/GetAllTags​/{customerId}/{tenantId}
    paginationConfig = paginationConfig == undefined ? new ApiPaginationConfig() : paginationConfig;
    let params: BaseURLParams = {
      url: this._createUrl(`Asset/GetAllTags/`),
      urlArguments: paginationConfig,
    }
    return this._apiHttpService.get(params);
  }



  public getAssetURI(value: Asset | Playlist | MediaAsset | string): string {
    let asset = (<Asset>value);
    if (asset?.AssetType) {

      if (asset.AssetType === assetTypes.Html) return Constants.IMAGES.thumb.html;
      if (asset.AssetType === assetTypes.Vast) return Constants.IMAGES.thumb.vast;
      if (asset.AssetType === assetTypes.Perpetual) return Constants.IMAGES.thumb.perpetual;

      if (!asset.ThumbnailURI) {
        if (asset.AssetType === assetTypes.Audio) return Constants.IMAGES.thumb.audio;
        if (asset.AssetType === assetTypes.Image) return Constants.IMAGES.thumb.image;
        if (asset.AssetType === assetTypes.Video) return Constants.IMAGES.thumb.video;

        // if there's no thumbnail for the asset type
        return Constants.IMAGES.thumb.url;
      }

      if (this.isUrl(asset.ThumbnailURI)) return asset.ThumbnailURI;

      return this._createUrl(`Asset/Files/${asset.ThumbnailURI}`);
    }

    if (typeof value == 'string') {
      return this.isUrl(value) ? <string>value : this._createUrl(`Asset/Files/${value}`);
    }

    let playlist = (<Playlist>value);
    if (playlist?.Id) {

      // Playlist cover asset thumb URL is generated automatically by backend
      // if not then we uses default thumb url.
      if (!playlist.CoverAssetThumbURI) {
        return Constants.IMAGES.thumb.playlist;
      }

      if (this.isUrl(playlist.CoverAssetThumbURI)) return playlist.CoverAssetThumbURI;

      return this._createUrl(`Asset/Files/${playlist.CoverAssetThumbURI}`);
    }

    return '';
  }

  public async downloadAsset(asset: Asset | MediaAsset) {
    var url = this.getAssetURI(asset["AssetURI"] || asset);
    // Download file by url
    var response = await fetch(url)
    // Convert response body to blob
    var blob = await response.blob();

    var ext = asset.FileName.split('.').pop();
    var filename = `${asset.Name}.${ext}`;

    this.handleBlobDownload(blob, filename, response.type);
  }
}

export enum assetTypes {
  Video = 'VIDEO',
  Audio = 'AUDIO',
  Image = 'IMAGE',
  Html = 'HTML',
  Vast = 'VAST',
  Perpetual = 'PERPETUAL',
  Interactive = 'INTERACTIVE',
};

export enum viewType {
  List = 'List',
  Grid = 'Grid',
};

export interface SearchOptions {
  tag: string;
  exact: boolean,
  inUse: boolean,
  name: string,
  assetType: assetTypes,
};
export interface Asset {
  Active?: boolean,//used locally
  Id: string,
  Name: string,
  FileName: string,
  AssetType: assetTypes,
  CustomerId: string,
  KBytesLength: number,
  Duration: number,
  Width: number,
  Height: number,
  Tags: string | null,
  ThumbnailURI: string,
  uriTumb?: string | null,
  IMAGE_TYPE?: string,
  URL_TYPE?: string,
  VIDEO_TYPE?: string,
  VIDEOSTREAMING_TYPE?: string,
  HTML_TYPE?: string,
  HTML_DYNAMIC_TYPE?: string,
  HTML_FRIENDLY_TYPE?: string,
  Selected?: boolean,
  InUse?: number,
  AssetURI?: string | null,
  AssetTypeStr?: string | null,
  TenantId?: string,
  LastUpdatedTs?: number,
  CreatedTime?: number,
  AssetRelations?: {
    ActionRules: Array<RelationActionRuleEntity>,
    Campaigns: Array<EntityName>,
    Devices: Array<EntityName>,
    LayoutBackgrounds: Array<any>,
    Layouts: Array<EntityName>,
    Playlists: Array<EntityName>,
    ZoneBackgrounds: Array<any>,
  }
  HasRelations?: boolean,
  Deleted?: boolean,
  Url?: string,
  DesignViews?: [],
  Layouts?: [],
  PlayListAssets?: [],
  ZoneBackgroundAssets?: [],
  ZoneMediaAssets?: [],
  Rules?: Array<Rule>,//used locally,
  PreviewURI?: string,
};

export interface MediaAsset {
  Id: string;
  Name: string;
  FileName: string;
  AssetType: string;
  CustomerId: string;
  TenantId: string;
  KBytesLength?: number;
  Duration?: number;
  Width?: number;
  Height?: number;
  Tags?: string;
  ThumbnailURI: string;
  PreviewURI: string;
  InUse?: number;
}

export interface RelationActionRuleEntity extends EntityName {
  LayoutId: string,
  LayoutName: string,
}

export interface Tags {
  Id: string;
  Name: string;
  Tags?: string;
}



export function validateAssetName(name) {
  name = name?.trim();

  // Length validation
  if (name == undefined || name.length === 0 || name.length > 255) {
    return { valid: false, error: "Asset name must be between 1 and 255 characters long." };
  }

  // Remove accents
  const normalizedName = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  if (normalizedName !== name) {
    return { valid: false, error: "Asset name cannot contain accents." };
  }

  // Special characters validation
  const specialCharRegex = /[\/\\\"\'\*\;\?\[\]\(\)\~\!\$\{\}\<\>\#\@\&|\s\t\r]/;
  if (specialCharRegex.test(name)) {
    return { valid: false, error: "Asset name contains invalid characters or spaces." };
  }

  // Start and end validation
  if (/^[.\-_]|[.\-_]$/.test(name)) {
    return { valid: false, error: "Asset name cannot start or end with a dot, hyphen, or underscore." };
  }

  // Allowed characters validation
  if (!/^[a-zA-Z0-9.\-_]+$/.test(name)) {
    return { valid: false, error: "Asset name can only contain alphanumeric characters, dots, hyphens, and underscores." };
  }

  // Encourage descriptive names
  if (name.length < 5) {
    return { valid: false, error: "Asset name should be descriptive and meaningful (at least 5 characters)." };
  }

  // If all validations pass
  return { valid: true, error: null };
}