import { Injectable } from '@angular/core';
import { toastType } from './vn-toasts.component';

@Injectable({
  providedIn: 'root'
})
export class VnToastService {
  public toasts: Array<Toast> = [];

  constructor() { (<any>window).vnToastService = this; }

  show(body: string, header?: string, type?: toastType, delay?: number) {
    if (type == undefined) type = 'default';
    if (delay == undefined) delay = 5000;
    // first we search to see if we have the message already queued,
    // if it is, we don't add it, and instead we just increment the delay
    let exists: boolean = false;
    for (let it = 0; it < this.toasts.length; it++) {
      let toast = this.toasts[it];
      if (toast.body.trim().toLocaleLowerCase() == body.trim().toLocaleLowerCase() &&
        toast.header?.trim().toLocaleLowerCase() == header?.trim().toLocaleLowerCase() &&
        toast.type == type) {
        exists = true;
        this.toasts[it].delay += delay;
        it = this.toasts.length;
      }
    }
    if (!exists) {
      let result = { header, body, type, delay } as Toast
      this.toasts.push(result);
      return result;
    }
    return undefined;
  }

  remove(toast) {
    let index = this.toasts.indexOf(toast);
    if (index > -1) {
      this.toasts.splice(index, 1);
    }
  }
  success(message: string, title?: string, delay?: number) {
    return this.show(message, title, 'success', delay);
  }
  info(message: string, title?: string, delay?: number) {
    return this.show(message, title, 'info', delay);
  }
  warn(message: string, title?: string, delay?: number) {
    return this.show(message, title, 'warn', delay);
  }
  error(message: string, title?: string, delay?: number) {
    return this.show(message, title, 'error', delay);
  }
}

interface Toast {
  body: string;
  header?: string;
  type?: 'success' | 'info' | 'warn' | 'error',
  delay?: number;
}
